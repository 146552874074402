<template>
  <ez-content>
    <ez-container>
      <ez-facts
        v-if="facts.length > 0"
        :limit="facts.length"
        :max="facts.length"
        :fluid="true"
        :items="facts"
      />
      <ez-box>
        <template slot="body">
          <ez-sub-header>
            <template slot="title">
              <div v-html="title"></div>
            </template>
          </ez-sub-header>
          <template v-if="!loading">
            <app-stub-box
              v-if="empty"
              :image="require('../assets/icons/snooze.svg')"
              :title="$t('purchases.no-data-title')"
              :description="$t('purchases.no-data-description')"
            />
            <ez-table
              class="table"
              v-if="!empty"
              :data="displayItems"
              stripe
              sortable="custom"
              @sort-change="onSortChange"
              >
              <div slot="empty">{{ $t("no-data") }}</div>
              <ez-table-column
                prop="date"
                sortable
                :label="$t('purchases.fields.date')"
                :width="180">
                <template slot-scope="scope">{{scope.row.createDate | userTime}}</template>
              </ez-table-column>
              <ez-table-column prop="product" sortable :label="$t('purchases.fields.product')">
                <template slot-scope="scope">
                  <ez-link
                    target="_blank"
                    :href="scope.row.paymentUrl"
                  >
                    {{ scope.row.productName }}
                  </ez-link>
                  <!-- {{scope.row.productName}} -->
                </template>
              </ez-table-column>
              <ez-table-column prop="amount" sortable :label="$t('purchases.fields.amount')">
                <template slot-scope="scope">
                  {{ `${currencySymbol} ${scope.row.amount}`.trim() }}
                </template>
              </ez-table-column>
              <ez-table-column
                prop="transactionType"
                sortable
                :label="$t('purchases.fields.transaction-type')"
                :width="180"
              >
                <template slot-scope="scope">{{scope.row.transactionTypeLabel}}</template>
              </ez-table-column>
              <ez-table-column prop="status" sortable :label="$t('purchases.fields.status')">
                <template slot-scope="scope">{{scope.row.transactionStatusLabel}}</template>
              </ez-table-column>
              <ez-table-column
                prop="transactionId"
                sortable
                :label="$t('purchases.fields.transaction-id')"
                :width="130">
                <template slot-scope="scope">{{scope.row.transactionId}}</template>
              </ez-table-column>
              <ez-table-column
                prop="source"
                sortable
                :label="$t('purchases.fields.source')"
                :width="200">
                <template slot-scope="scope">{{scope.row.source}}</template>
              </ez-table-column>
            </ez-table>
            <ez-pagination
              v-if="items.length"
              :page="pageNumber"
              :page-count="totalPages"
              :page-range="pageRange"
              :limit="pageSize"
              :limit-options="limitOptions"
              @change="onPaginationChange"
            />
          </template>
        </template>
      </ez-box>
    </ez-container>
  </ez-content>
</template>

<script>
import get from 'lodash/get';

import contactMixin from '@/mixins/contact';
import currencyMixin from '@/mixins/currency';

import { sortMetaData } from '@/api/PurchasesRepository';

import iconPurchases from '@/assets/icons/purchases.svg';
import iconWallet from '@/assets/icons/wallet.svg';
import iconIncome from '@/assets/icons/income.svg';
import userTime from '@/services/formatters/userTime';
import labels from './purchases/labels';
import * as actions from '../store/funnels/actions';
import * as getters from '../store/funnels/getters';

const STORE_PREFIX = 'purchases';

export default {
  name: 'AppPurchases',
  mixins: [contactMixin, currencyMixin],
  filters: {
    userTime,
  },
  data() {
    return {
      page: 1,
      pageCount: 20,
      pageRange: 7,
      limit: 10,
      limitOptions: [10, 20, 30],
      title: this.$t('purchases.title'),
      pageTitle: this.$t('header.menuLinks.purchases'),
      labels,
      sorting: {
        prop: 'date',
        order: sortMetaData.date.defaultSortDirection,
      },
    };
  },
  created() {
    // this.getData({ limit: this.limit });
    this.loadItems({});
  },
  methods: {
    // load data
    loadItems(params = {}) {
      const actualParams = {
        contactId: this.currentContactId,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...params,
      };
      this.$store.dispatch(
        `${STORE_PREFIX}/${actions.GET_ITEMS}`,
        actualParams,
      );
    },

    // sorting
    onSortChange(value) {
      const { prop } = value;
      const { prop: currentProp, order: currentOrder } = this.sorting;
      // eslint-disable-next-line
      const newOrder = currentProp === prop
        ? (currentOrder === 'ascending' ? 'descending' : 'ascending')
        : sortMetaData[prop].defaultSortDirection;
      this.sorting = {
        prop,
        order: newOrder,
      };
      this.loadItems({ pageNumber: 1, sortProp: prop, sortOrder: newOrder });
    },

    // pagination
    onPaginationChange({ limit, page }) {
      const { prop, order } = this.sorting;
      this.loadItems({
        pageSize: limit, pageNumber: page, sortProp: prop, sortOrder: order,
      });
    },
  },
  computed: {
    facts() {
      if (this.currentContact == null) {
        return [];
      }
      return [
        {
          id: 1,
          header: get(this.currentContact, 'attributes.purchases', 'N/A'),
          icon: iconPurchases,
          text: this.$t('purchases.widgets.purchases'),
        },
        {
          id: 2,
          header: `${this.currencySymbol} ${get(this.currentContact, 'attributes.revenue', 'N/A')}`.trim(),
          icon: iconWallet,
          text: this.$t('purchases.widgets.revenue'),
        },
        {
          id: 3,
          header: `${this.currencySymbol} ${
            get(
              this.currentContact,
              'attributes.recurring-income-per-month',
              'N/A',
            )
          }`.trim(),
          icon: iconIncome,
          text: this.$t('purchases.widgets.recurring-income-per-month'),
        },
      ];
    },

    // items to display in table and paging props
    items() {
      const items = this.$store.getters[`${STORE_PREFIX}/${getters.ITEMS}`];
      return items;
    },
    displayItems() {
      const paymentData = {
        ds24: {
          url: 'https://www.digistore24.com/product/',
          paymentIdField: 'product-id',
        },
        elopage: {
          url: 'https://elopage.com/cabinet/transfers/',
          paymentIdField: 'transaction-id',
        },
      };
      // TODO: process items for display
      return this.items.map((item) => {
        const { attributes } = item;
        const { url, paymentIdField } = paymentData[attributes['payment-type']];
        return {
          amount: attributes.amount,
          createDate: attributes['dt-create'],
          productName: attributes['product-name'],
          productType: attributes['product-type'],
          productTypeLabel: labels.productTypes[attributes['product-type']],
          source: attributes.source,
          transactionId: attributes['transaction-id'],
          transactionStatus: attributes['transaction-status'],
          transactionStatusLabel:
            labels.transactionStatuses[attributes['transaction-status']],
          transactionType: attributes['transaction-type'],
          transactionTypeLabel:
            labels.transactionTypes[attributes['transaction-type']],
          paymentUrl: `${url}${attributes[paymentIdField]}`,
        };
      });
    },
    totalItems() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.TOTAL_ITEMS}`];
    },
    empty() {
      return !(this.items || []).length;
      // return this.totalItems === 0;
    },
    totalPages() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.TOTAL_PAGES}`];
    },
    loading() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.LOADING}`];
    },
    pageSize() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.PAGE_SIZE}`];
    },
    pageNumber() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.PAGE_NUMBER}`];
    },
    enabled() {
      return this.currentContact != null;
    },
  },
};
</script>
