import * as types from './types';

export default {
  productTypes: {
    [types.PRODUCT_TYPE_DOWN_SELL]: 'Sell',
    [types.PRODUCT_TYPE_MAIN]: 'Main',
    [types.PRODUCT_TYPE_UP_SELL]: 'Up sell',
  },
  transactionStatuses: {
    [types.TRANSACTION_STATUS_ABORTED]: 'Aborted',
    [types.TRANSACTION_STATUS_COMPLETED]: 'Completed',
    [types.TRANSACTION_STATUS_PAYING]: 'Paying',
    [types.TRANSACTION_STATUS_REMINDING]: 'Reminding',
    [types.TRANSACTION_STATUS_UNPAID]: 'Unpaid',
  },
  transactionTypes: {
    [types.TRANSACTION_TYPE_INSTALLMENT]: 'Installment',
    [types.TRANSACTION_TYPE_SINGLE_PAYMENT]: 'Single payment',
    [types.TRANSACTION_TYPE_SUBSCRIPTION]: 'Subscription',
  },
};
