export const PRODUCT_TYPE_MAIN = 1;
export const PRODUCT_TYPE_UP_SELL = 2;
export const PRODUCT_TYPE_DOWN_SELL = 3;

export const TRANSACTION_TYPE_SINGLE_PAYMENT = 1;
export const TRANSACTION_TYPE_SUBSCRIPTION = 2;
export const TRANSACTION_TYPE_INSTALLMENT = 3;

export const TRANSACTION_STATUS_PAYING = 1;
export const TRANSACTION_STATUS_ABORTED = 2;
export const TRANSACTION_STATUS_UNPAID = 3;
export const TRANSACTION_STATUS_REMINDING = 4;
export const TRANSACTION_STATUS_COMPLETED = 5;
